export function PreteritoImperfectoNameRu() {
	return "Pretérito Imperfecto";
}

export function PreteritoImperfectoRu() {
	return <div>
		<h4>Pretérito Imerfecto</h4>
		<p>Pretérito Imperfecto используется для описания прошлых действий, которые происходили регулярно или продолжались в течение определённого периода времени в прошлом. Также это время может использоваться для описания фона или обстоятельств, в рамках которых произошло другое действие. </p>
        <h5>Примеры:</h5>
		<blockquote>
			<p>Todos los días yo caminaba por el parque. (Каждый день я гулял по парку.)</p>
			<p>Mientras yo leía, mi hermano miraba la televisión. (Пока я читал, мой брат смотрел телевизор.)</p>
			<p>Cuando era joven, viajaba mucho. (Когда я был молод, я много путешествовал.)</p>
		</blockquote>
        <p>Pretérito Imperfecto применяется в следующих случаях:</p>
		<blockquote>
			<p>- для описания прошлых действий, которые не ограничены конкретным моментом времени;</p>
			<p>- для выражения привычек в прошлом;</p>
			<p>- для описания обстоятельств или фона, на котором происходило другое действие;</p>
			<p>- в рассказах для создания атмосферы прошлого;</p>
        <p>- для описания людей, мест, объектов и ситуаций в прошлом, а также для выражения возраста, времени, погоды и эмоций.</p>
		</blockquote>
		<p>Чтобы сформировать Pretérito Imperfecto, необходимо использовать основу глагола и добавить соответствующие окончания. Окончания различаются для глаголов первого <strong>-ar</strong>, второго <strong>-er</strong> и третьего <strong>-ir</strong> спряжения: </p>
		<blockquote>
			<p>- глаголы на <strong>-ar</strong>:<br /> -aba, -abas, -aba, -ábamos, -abais, -aban;</p>
			<p>- глаголы на <strong>-er</strong> и <strong>-ir</strong>:<br /> -ía, -ías, -ía, -íamos, -íais, -ían.</p>
		</blockquote>
		<br/>
		<p>Примеры образования Pretérito Imperfecto</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						hablar <span className="red">(1)</span> <p><i>(говорить)</i></p>
					</td>
					<td className="head-item">
						comer <span className="red">(2)</span> <p><i>(есть)</i></p>
					</td>
					<td className="head-item">
						vivir <span className="red">(3)</span> <p><i>(жить)</i></p>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">habl<span className="red">aba</span></td>
					<td className="body-item">com<span className="red">ía</span></td>
					<td className="body-item">viv<span className="red">ía</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">habl<span className="red">abas</span></td>
					<td className="body-item">com<span className="red">ías</span></td>
					<td className="body-item">viv<span className="red">ías</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">habl<span className="red">aba</span></td>
					<td className="body-item">com<span className="red">ía</span></td>
					<td className="body-item">viv<span className="red">ía</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">habl<span className="red">ábamos</span></td>
					<td className="body-item">com<span className="red">íamos</span></td>
					<td className="body-item">viv<span className="red">íamos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">habl<span className="red">abais</span></td>
					<td className="body-item">com<span className="red">íais</span></td>
					<td className="body-item">viv<span className="red">íais</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">habl<span className="red">aban</span></td>
					<td className="body-item">com<span className="red">ían</span></td>
					<td className="body-item">viv<span className="red">ían</span></td>
				</tr>
			</tbody>
		</table>
		<br/>	
		<p>Возвратные глаголы, например, <strong>llamarse, terminarse, olividarse</strong>, как обычно, спрягаются с возвратным местоимением. В случае с Preterito Imperfecto, это будет выглядеть следующим образом:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						llamarse <i>(называться)</i></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">me</span> llamaba</td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">te</span> llamabas</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">se</span> llamaba</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">nos</span> llamábamos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">os</span> llamabais</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">se</span> llamaban</td>
				</tr>
			</tbody>
		</table>
		<br />
        <p>Для Pretérito Imperfecto характерно наличие очень малого количества неправильных форм, что делает его одним из более простых времён испанского языка. Тем не менее, есть несколько глаголов, которые изменяются нестандартным образом:</p>
        
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">ser <p><i>(быть)</i></p></td>
					<td className="head-item">ir <p><i>(идти)</i></p></td>
					<td className="head-item">ver <p><i>(смотреть)</i></p ></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">era</td>
					<td className="body-item">iba</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">eras</td>
					<td className="body-item">ibas</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">era</td>
					<td className="body-item">iba</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">eramos</td>
					<td className="body-item">ibamos</td>
					<td className="body-item">veíamos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">erais</td>
					<td className="body-item">ibáis</td>
					<td className="body-item">veías</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">eran</td>
					<td className="body-item">iban</td>
					<td className="body-item">veían</td>
				</tr>
			</tbody>
		</table>
		<br />
        <h5>Формы "había" и "hubo"</h5>
		<p>Часто происхолит путаница в употреблении <strong>había</strong> (форма от <i>haber</i> в Imperfecto) и <strong>hubo</strong>  (форма от <i>haber</i> в Indefinido) для выражения "было" или "существовало". <strong> Había</strong>  используется для описания прошлого фона или контекста, а <strong> hubo</strong> — для указания на конкретное событие.</p>
		<h5>Слова - индикаторы</h5>
		<p>Эти слова или фразы могут помочь определить, когда уместно использовать Pretérito Imperfecto, особенно для описания прошлых действий, привычек или обстоятельств.</p>
		<blockquote>
			<p><strong>siempre</strong> - всегда (указывает на привычное действие в прошлом)</p>
			<p><strong>todos los días</strong> - каждый день (обозначает регулярные действия)</p>
			<p><strong>cada año</strong> - каждый год (для описания ежегодных событий или действий)</p> 
			<p><strong>mientras</strong> - пока (используется для указания на одновременность действий в прошлом)</p>
			<p><strong>en aquella época</strong> - в ту эпоху (для обозначения временного периода в прошлом)</p>
			<p><strong>antes</strong> - раньше (относится к действиям или привычкам, которые были обычными в прошлом)</p>
			<p><strong>nunca</strong> - никогда (может использоваться для выражения отсутствия действия в прошлом)</p>
			<p><strong>a menudo</strong> - часто (указывает на частоту действий в прошлом)</p>
			<p><strong>de vez en cuando</strong> - часто (для нерегулярных или случайных действий в прошлом)</p>
			<p><strong>muchas veces</strong> - часто (подчёркивает повторение действия в прошлом)</p>
		</blockquote>
	</div>;
}