import React, { useState, useEffect } from 'react';
import PillsTab from '../pillsTab/pillsTab';
import TestSection from './testSection'
import './tests.css'

export default function Tests() {
    const [tests, setTests] = useState([]);

    useEffect(() => {
        GetTests();
    }, []);

    const GetTests = () => {
        fetch(`api/tests/list`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                const tests = json.reduce((tests, test) => {
                    if (!tests[test.section]) tests[test.section] = [];
                    tests[test.section].push(test);
                    return tests;
                }, {});
                setTests(tests);
            })
            .catch(error => console.error(error));
    }

    const tabFunc = (key) => key

    const bodyFunc = (key) => {
        return tests[key].map((comp, i) => <TestSection key={i} title={comp.title} tests={comp.tests} />)
    }

    return <section className="paddingTop32 paddingBottom48">
        <PillsTab
            tabs={Object.keys(tests)}
            body={Object.keys(tests)}
            tabFunc={tabFunc}
            bodyFunc={bodyFunc}
        />
    </section>
}
