import React, { useState } from 'react';
import SpecialChars, { UseFocus } from '../specialChars/specialChars'

export default function TestInput(props) {
    const [inputRef, setInputFocus] = UseFocus();
    const [showChars, setShowChars] = useState(false);
    const [shiftPressed, setShiftPressed] = useState(false);
    var timer = null;

    const onFocus = (event) => {
        setShowChars(true);
        props.handleChange(event);
    }

    const onBlur = () => {
        timer = setTimeout(() => setShowChars(false), 50);
    }

    const onChange = (event) => {
        props.handleChange(event);
    }

    const onKeyDown = (event) => {
        if (shiftPressed !== event.shiftKey) {
            setShiftPressed(event.shiftKey);
        }
    }

    const onKeyUp = (event) => {
        setShiftPressed(event.shiftKey);
    }

    const buttonFocus = () => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }

    return <>
        <input ref={inputRef} name={props.name} className="testBox" autoComplete="off"
            onFocus={onFocus} onBlur={onBlur} onChange={onChange} onKeyDown={onKeyDown} onKeyUp={onKeyUp} />
        {showChars
            ? <div className="input-dropdown">
                <SpecialChars
                    inputFocus={setInputFocus}
                    inputRef={inputRef}
                    focused={buttonFocus}
                    shiftPressed={() => shiftPressed}
                />
            </div>
            : ''
        }
    </>
}