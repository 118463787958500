import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ContactForm from '../contactForm'
import { useTranslation } from 'react-i18next';

import './verbs.css'

window.onpopstate = () => window.location.reload();

export default function Verbs() {
    const verbUrl = "/conjugador/";
    const { t, i18n } = useTranslation();
    const [verbList, setVerbs] = useState([]);
    const [selectedVerb, setSelectedVerb] = useState(null);
    const [selected, setSelected] = useState("");

    const pronouns = [
        "Yo",
        "T\xFA",
        "\xC9l, Ella, Usted",
        "Nosotros, Nosotras",
        "Vosotros, Vosotras",
        "Ellos, Ellas, Ustedes",
    ];

    useEffect(() => {
        getVerbs();

        if (window.location.pathname.length > verbUrl.length) {
            const verb = window.location.pathname.replace(verbUrl, "");
            setSelected(verb);
            getVerb(verb);
        }
    }, []);

    const getVerbs = () => {
        fetch(`api/verb/list`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                setVerbs(json.map((elem) => ({ label: elem.name, value: elem.name })));
            })
            .catch(error => console.error(error));
    }

    const getVerb = (name) => {
        fetch(`api/verb/name/` + name)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                setSelectedVerb(json);
            })
            .catch(error => console.error(error));
    }

    const changeVerb = (verb) => {
        window.history.pushState(null, '', verbUrl + verb);
        setSelected(verb);
        getVerb(verb);
    }

    const handleChange = (event) => changeVerb(event.value);

    const generateSection = (section, index) => {

        return (
            <div className="clearBoth" key={index}>
                <label className="mbr-text mbr-fonts-style display-7">{section.name}</label>
                <hr />
                <div>
                    {section.tenses.map((tense, index) => generateTense(tense, index, section.name))}
                </div>
            </div>
        );
    }

    const generateTense = (tense, index, sectionName) => {

        return (
            <div key={index} className="floatLeft">
                <fieldset>
                    <legend className="mbr-text mbr-fonts-style display-9">{tense.name}</legend>
                    <table>
                        <tbody>
                            {tense.pronouns.map((value, index) =>
                                value && <tr key={index}>
                                    <td className="pronounsLabel mbr-text mbr-fonts-style display-9">{sectionName === 'Participio' || sectionName === 'Gerundio' ? '' : pronouns[index]}</td>
                                    <td className="mbr-text mbr-fonts-style display-9">{value}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </fieldset>
            </div>
        );
    }

    const handleClick = (e) => {
        e.preventDefault();
        const verb = e.currentTarget.innerText;
        changeVerb(verb);
    }        

    const generateSynonyms = () => {
        var once = true;
        return <>
            <br />
            <div>
                <strong>{t('verbs.Synonyms')}: </strong>
                {selectedVerb.synonyms.map((value, index) => {
                    const comma = !once;
                    once = false;
                    return <span key={index}>
                        {comma ? ", " : ""}
                        {verbList.some(verb => verb.value === value)
                            ? <a href={verbUrl + value} onClick={handleClick}>{value}</a>
                            : value
                        }
                    </span>
                })}
            </div>
        </>
    }

    const generateVerb = () => {
        return (
            <>
                <div className="floatLeft select-inline mbr-text mbr-fonts-style display-7">
                    {i18n.resolvedLanguage === 'ru'
                        ? selectedVerb.translations.ru
                        : selectedVerb.translations.en}&nbsp;
                    <span style={{ "color": "silver" }}>({i18n.resolvedLanguage})</span>
                </div>
                <div className="clearBoth">
                    {selectedVerb.synonyms && selectedVerb.synonyms.length > 0 && generateSynonyms()}
                    <br />
                    {selectedVerb.sections.map((section, index) => generateSection(section, index))}
                </div>
            </>
        );
    }

    return (
        <div>
             <section className="paddingTop32 paddingBottom48">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('verbs.Title')}</strong></h4>
                        <p className="mbr-text mbr-fonts-style display-7">{t('verbs.Description')}</p>
                    </div>
                </div>
            </section>
            <section className="paddingBottom48">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <div className="select floatLeft configuration mbr-text mbr-fonts-style display-7">
                            <Select options={verbList} onChange={handleChange} placeholder={t('verbs.Select')}
                                value={verbList.filter((option) => option.value === selected)}
                            />
                        </div>
                        {selectedVerb && generateVerb()}
                    </div>
                </div>
            </section>
            <ContactForm />
        </div>
    );
}
