import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

export default function Start(props) {
    const maxVerbsCount = 10;
    const { t } = useTranslation();
    const [verbList, setVerbs] = useState([]);
    const [selectedVerbs, setSelectedVerbs] = useState([]);
    const [tenses, setTenses] = useState([]);
    const [checkedTenses, setTenseChecked] = useState(["Indicativo.Presente"]);
    const [checkedGroups, setGroupChecked] = useState([1, 2, 3]);
    const [checkedRegular, setRegularChecked] = useState(["regular", "irregular"]);
    const [randomCount, setRandomCount] = useState(maxVerbsCount);

    useEffect(() => {
        getVerbs();
        getTenses();
    }, []);

    const handleTenseCheck = (event) => {
        var updatedList = [...checkedTenses];
        if (event.target.checked) {
            //updatedList = [...checkedTenses, event.target.value];
            updatedList = [event.target.value];
        } else {
            updatedList.splice(checkedTenses.indexOf(event.target.value), 1);
        }

        // TODO: rollback => 
        setTenseChecked(updatedList);

        var inputs = document.querySelectorAll("input[name='tenses']");
        inputs.forEach(i => {
            if (checkedTenses.some(x => x === i.value)) {
                i.checked = false;
            }
        });
    };

    const handleGroupCheck = (event) => {
        var updatedList = [...checkedGroups];
        if (event.target.checked) {
            updatedList = [...checkedGroups, Number(event.target.value)];
        } else {
            updatedList.splice(checkedGroups.indexOf(Number(event.target.value)), 1);
        }
        setGroupChecked(updatedList);
    };

    const handleRegularCheck = (event) => {
        var updatedList = [...checkedRegular];
        if (event.target.checked) {
            updatedList = [...checkedRegular, event.target.value];
        } else {
            updatedList.splice(checkedRegular.indexOf(event.target.value), 1);
        }
        setRegularChecked(updatedList);
    };

    const generateTense = (sectionName, tenseName, index) => {
        var value = sectionName + '.' + tenseName;
        var tenseId = 'tense_' + index;
        var checked = checkedTenses.some(x => x === value);
        return (
            <div key={index} className="floatLeft tense">
                <input type="checkbox" id={tenseId} name="tenses" value={value} onChange={handleTenseCheck} checked={checked} />
                <label htmlFor={tenseId}>{tenseName}</label>
            </div>
        );
    }

    const generateTenseSection = (section, index) => {
        var sectionIndex = index * 100;
        return (
            <div className="section" key={index}>
                <label>{section.name}</label>
                <hr />
                <div>
                    {section.tenses.length && section.tenses.map((tense, index) =>
                        generateTense(section.name, tense.name, sectionIndex + index))}
                    {!section.tenses.length && generateTense(section.name, index)}
                </div>
            </div>
        );
    }

    const addRandomVerbs = () => {
        let verbCount = Math.min(maxVerbsCount - selectedVerbs.length, randomCount);
        if (verbCount > 0) {
            let randomVerbs = verbList
                .filter((el) => checkedGroups.includes(el.conjugation))
                .filter((el) => checkedRegular.includes(el.verbType))
                .filter((el) => {
                    return !selectedVerbs.some(x => x.name === el.name);
                })
                .sort(() => 0.5 - Math.random())
                .slice(0, verbCount);
            setSelectedVerbs([...selectedVerbs, ...randomVerbs]);
        }
    }

    const getVerbs = () => {
        fetch(`api/verb/list`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                setVerbs(json);
            })
            .catch(error => console.error(error));
    }

    const getTenses = () => {
        fetch(`gamet/tenses`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                setTenses(json);
            })
            .catch(error => console.error(error));
    }

    const startGame = () => {
        if (checkedTenses.length === 0) {
            alert('Tense not selected');
            return;
        }

        props.startGame({
            tenses: checkedTenses,
            groups: checkedGroups,
            regular: checkedRegular,
            verbs: selectedVerbs.map((x) => x.name),
        });
    }

    return (
        <div className="configuration mbr-text mbr-fonts-style display-7">
            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('game.rule_1')}</strong></h4>
            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><i>{t('game.rule_2')}</i></h4>
            <blockquote>
                <p>
                    <label>Grupos de verbos</label>
                    <input type="checkbox" id="group_1" name="group" value={1} onChange={handleGroupCheck} defaultChecked={true} />
                    <label htmlFor="group_1">-ar</label>
                    <input type="checkbox" id="group_2" name="group" value={2} onChange={handleGroupCheck} defaultChecked={true} />
                    <label htmlFor="group_2">-er</label>
                    <input type="checkbox" id="group_3" name="group" value={3} onChange={handleGroupCheck} defaultChecked={true} />
                    <label htmlFor="group_3">-ir</label>
                </p>
                <p>
                    <label>Tipo de verbo</label>
                    <input type="checkbox" id="regular" name="regular" value="regular" onChange={handleRegularCheck} defaultChecked={true} />
                    <label htmlFor="regular">regular</label>
                    <input type="checkbox" id="irregular" name="irregular" value="irregular" onChange={handleRegularCheck} defaultChecked={true} />
                    <label htmlFor="irregular">irregular</label>
                </p>
                <div>
                    <input
                        type="number"
                        id="verbCount"
                        value={randomCount}
                        min="0"
                        max={maxVerbsCount}
                        onChange={(el) => setRandomCount(el.target.value)} />
                    <button className="btn btn-primary" onClick={addRandomVerbs} >{t('game.Add_random')}</button>
                </div>
            </blockquote>
            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><i>{t('game.Manual_verb_selection')}</i></h4>
            <div className="game-verbs-select">
                <Select
                    isMulti
                    name="verbs"
                    options={verbList}
                    value={selectedVerbs}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(o) => setSelectedVerbs(o)}
                    isOptionDisabled={() => selectedVerbs.length >= maxVerbsCount}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={t('game.Select')}
                    styles={{
                        option: (provider) => ({
                            ...provider,
                            padding: 5,
                        }),
                    }}
                />
                <br />
                <p><i>{t('game.Choose_tense_and_go')}</i> </p>
                <button className="btn btn-primary" onClick={startGame}>{t('game.Go')}</button>
            </div>
           
            {tenses.map((section, index) => generateTenseSection(section, index))}
            <br /><br />
            <div><button className="btn btn-primary" onClick={startGame}>{t('game.Go')}</button></div>
        </div>
    );
}