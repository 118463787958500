import React from 'react';
import { useTranslation } from 'react-i18next';
import './NavMenu.css';

export default function NavMenu() {
    const { t } = useTranslation();
    return (
        <section data-bs-version="5.1" className="menu cid-s48OLK6784" id="menu1-h">
            <nav className="navbar navbar-dropdown navbar-expand-lg">
                <div className="container">
                    <div className="navbar-brand">
                        <span className="navbar-logo">
                            <a href="/">
                                <img src="assets/images/small3-337x157.png" alt="TODO" style={{ "height" : "7.1rem"}} />
                            </a>
                        </span>
                
                    </div>
                    <button className="navbar-toggler shadow-none" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                            <li className="nav-item"><a className="nav-link link text-black display-4" href="/entrenador">{t('menu.Trainer')}</a></li>
                            <li className="nav-item"><a className="nav-link link text-black display-4" href="/conjugador">{t('menu.Conjugator')}</a></li>
                            <li className="nav-item"><a className="nav-link link text-black display-4" href="/grammar">{t('menu.Grammar')}</a></li>
                            <li className="nav-item"><a className="nav-link link text-black display-4" href="/tests">{t('menu.Tests')}</a></li>
                            {/*<li className="nav-item"><a className="nav-link link text-black display-4" href="/sinonimos">Sin�nimos</a></li>*/}
                        </ul>
                    </div>
                </div>
            </nav>

        </section>
    );
}
