export function PreteritoIndefenidoNameEn() {
	return "Pretérito Indefenido";
}

export function PreteritoIndefenidoEn() {
    return <div>
         <h4>Pretérito Indefenido (Pretérito Simple)</h4>
        <p>Pretérito Indefenido (Pretérito Simple) is similar to the Past Simple in English grammar - completed past tense. It is used to indicate the completion of an action in the past. This tense may describe:</p>
        <blockquote>
            <p>- a single completed action in the past;</p>
            <p>- long-term events that happened in the past but have no relation to the present time;</p>
            <p>- dates and historical events, biography;</p>
            <p>- short sequential actions performed in the past.</p>
        </blockquote>
        <h5>Examples</h5>
        <blockquote>
        <p>El año pasado viajamos a España.</p>
			<p>Ayer comí una pizza deliciosa.</p>
			<p>Ellos compraron una casa nueva el mes pasado.</p>
			<p>María escribió una carta esta mañana.</p>
		</blockquote>
        
        <p>Pretérito Indefenido (Pretérito Simple) is formed by changing the ending of the infinitive form of the verb. The endings differ for verbs of the first conjugatiob(-ar), and verbs of the second (-er) and third (-ir) conjugations. To form Pretérito Indefenido the following endings must be added.</p>

		<p>To conjugate in the Pretérito Indefinido (Pretérito Simple), simply remove the infinitive endings of the verb and add the endings according to this table. This rule applies only to regular verbs.</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item"><span className="red"><i>(1)</i></span></td>
					<td className="head-item"><span className="red"><i>(2)</i></span></td>
					<td className="head-item"><span className="red"><i>(3)</i></span></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">-é</span></td>
					<td className="body-item"><span className="red">-í</span></td>
					<td className="body-item"><span className="red">-í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">-aste</span></td>
					<td className="body-item"><span className="red">-iste</span></td>
					<td className="body-item"><span className="red">-iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">-ó</span></td>
					<td className="body-item"><span className="red">-ió</span></td>
					<td className="body-item"><span className="red">-ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">-amos</span></td>
					<td className="body-item"><span className="red">-imos</span></td>
					<td className="body-item"><span className="red">-imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">-asteis</span></td>
					<td className="body-item"><span className="red">-isteis</span></td>
					<td className="body-item"><span className="red">-isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">-aron</span></td>
					<td className="body-item"><span className="red">-ieron</span></td>
					<td className="body-item"><span className="red">-ieron</span></td>
				</tr>
			</tbody>
		</table>
		<h5>Examples</h5>
        <table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">cantar<span className="red"><i>(1)</i></span>to sing</td>
					<td className="head-item">beber<span className="red"><i>(2)</i></span>to drink</td>
					<td className="head-item">vivir<span className="red"><i>(3)</i></span>to live</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">cant<span className="red">é</span></td>
					<td className="body-item">beb<span className="red">-í</span></td>
					<td className="body-item">viv<span className="red">-í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">cant<span className="red">aste</span></td>
					<td className="body-item">beb<span className="red">iste</span></td>
					<td className="body-item">viv<span className="red">iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">cant<span className="red">ó</span></td>
					<td className="body-item">beb<span className="red">ió</span></td>
					<td className="body-item">viv<span className="red">ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">cant<span className="red">amos</span></td>
					<td className="body-item">beb<span className="red">imos</span></td>
					<td className="body-item">viv<span className="red">imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">cant<span className="red">asteis</span></td>
					<td className="body-item">beb<span className="red">isteis</span></td>
					<td className="body-item">viv<span className="red">isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">cant<span className="red">aron</span></td>
					<td className="body-item">beb<span className="red">ieron</span></td>
					<td className="body-item">viv<span className="red">ieron</span></td>
				</tr>
			</tbody>
		</table>
        
		<p>Reflexive words, for example, lavarse, decidirse, ponerse, as usual, are conjugated with a reflexive pronoun. In Pretérito Indefenido (Pretérito Simple) case, it will look like this:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">lavarse<p><i>(to be washed)</i></p></td>
					<td className="head-item">decidirse<p><i>(to be decided)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">me lav<span className="red">é</span></td>
					<td className="body-item">me decid<span className="red">í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">te lav<span className="red">aste</span></td>
					<td className="body-item">te decid<span className="red">iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">se lav<span className="red">ó</span></td>
					<td className="body-item">se decid<span className="red">ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">nos lav<span className="red">amos</span></td>
					<td className="body-item">nos decid<span className="red">imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">os lav<span className="red">asteis</span></td>
					<td className="body-item">os decid<span className="red">isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">se lav<span className="red">aron</span></td>
					<td className="body-item">se decid<span className="red">ieron</span></td>
				</tr>
			</tbody>
		</table>
		<p>In Pretérito Indefenido significant number of verbs are irregular and conjugate according to different rules. They can be divided into several groups.</p>
		<h5> Verbs with a stem change from -e- to -i-:</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">pedir <p><i>(to be able to)</i></p></td>
					<td className="head-item">sentir<p><i>(to feel)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">pedí</td>
					<td className="body-item">sentí</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">pediste</td>
					<td className="body-item">sentiste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">pidió</td>
					<td className="body-item">sintió</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">pedimos</td>
					<td className="body-item">sentimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">pedisteis</td>
					<td className="body-item">sentisteis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">pidieron</td>
					<td className="body-item">sintieron</td>
				</tr>
			</tbody>
		</table>
		<h5> Verbs with a stem change from -o- to -u-:</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">dormir<p><i>(to sleep)</i></p></td>
					<td className="head-item">morir<p><i>(to die)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">dormí</td>
					<td className="body-item">morí</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">dormiste</td>
					<td className="body-item">moriste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">durmió</td>
					<td className="body-item">murió</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">dormimos</td>
					<td className="body-item">morimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">dormisteis</td>
					<td className="body-item">moristeis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">durmieron</td>
					<td className="body-item">murieron</td>
				</tr>
			</tbody>
		</table>
		<h5> Verbs with changing from -i- to -y- (in the third form):</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">leer <p><i>(to read)</i></p></td>
					<td className="head-item">construir <p><i>(to build)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr><td className="body-item">yo</td>
					<td className="body-item">leí</td>
					<td className="body-item">construí</td>
				</tr><tr><td className="body-item">tú</td>
					<td className="body-item">leíste</td>
					<td className="body-item">construiste</td>
				</tr><tr><td className="body-item">él/ella/Vd.</td>
					<td className="body-item">le<span className="red">y</span>ó</td>
					<td className="body-item">constru<span className="red">y</span>ó</td>
				</tr><tr><td className="body-item">nosotros/-as</td>
					<td className="body-item">leímos</td>
					<td className="body-item">construimos</td>
				</tr><tr><td className="body-item">vosotros/-as</td>
					<td className="body-item">leísteis</td>
					<td className="body-item">construisteis</td>
				</tr><tr><td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">leyeron</td>
					<td className="body-item">construyeron</td>
				</tr>
			</tbody>
		</table>
		<h5>Verbs with a changing in the ending (-j- is added and -i- disappears in the third person plural)</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">decir <p><i>(to say)</i></p></td>
					<td className="head-item">traer <p><i>(to bring)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">dije</td>
					<td className="body-item">traje</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">dijiste</td>
					<td className="body-item">trajiste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">dijo</td>
					<td className="body-item">trajo</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">dijimos</td>
					<td className="body-item">trajimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">dijisteis</td>
					<td className="body-item">trajisteis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">dijeron</td>
					<td className="body-item">trajeron</td>
				</tr>
			</tbody>
		</table>

		<h5> Words - indicators</h5>
		<p>In sentences often are used adverbs and phrases, which are indicators of completed past tense. Remember to clearly define Pretérito Indefenido.</p>
		<blockquote>
			<p><strong>ayer</strong> - yesterday</p>
			<p><strong>anteayer</strong>- day before yesterday</p>
			<p><strong>anoche </strong> - last night</p>
			<p><strong>la semana pasada</strong> - last week</p>
			<p><strong>el mes pasado, el invierno pasado, el año pasado </strong> - last month, last winter, last year </p>
			<p><strong>hace una semana </strong> - one week ago</p>
			<p><strong>hace 2 meses, hace 5 años</strong> - two months ago, five years ago</p>
            <p><strong>hace mucho...</strong> - long time ago</p>
        
		</blockquote>
	</div>;
}