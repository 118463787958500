import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: process.env.REACT_APP_LOCIZE_LNG,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    menu: {
                        Trainer: 'Verb Trainer',
                        Conjugator: 'Conjugator',
                        Grammar: 'Grammar',
                        Tests: 'Tests',
                        Privacy: 'Privacy policy',
                    },
                    game: {
                        initial_header: 'Welcome to Verbo Go Conjugation Trainer!',
                        initial_text: 'Just 10 minutes of trainings a day will significally expand your vocabulary and knowledge of most commonly used verb forms.<br /> To start, please follow the instructions below.',
                        game_header: 'Let\'s practice!',
                        game_text: '1. Enter verb in correct form and press button "$t(game.Check)".<br /> 2. If you need help, please press button "$t(game.Help_me)".<br />3. To start conjugate another verbs, please press button "$t(game.Finish)".',
                        statistic_header: 'Well done! Evaluate your results and start again!',
                        Tense: 'Tense',
                        Example: 'Example',
                        Your_verbs: 'Your verbs',
                        Check: 'Check',
                        Correct_answer: 'Correct!',
                        Error: 'Error!',
                        Next_word: 'Next word',
                        Help_me: 'Help me',
                        Finish: 'Finish',
                        Start_again: 'Start again',
                        Verbs: 'Verbs',
                        Attempts: 'Attempts',
                        Correct: 'Correct',
                        Errors: 'Errors',
                        Skipped: 'Skipped',
                        Success: 'Success',
                        rule_1: 'Let\'s Go! Select verb parameters and tense below!',
                        rule_2: 'Random verb selection: for random selection please check parameters and quantity (1 to 10) below and press button "Add random".',
                        Add_random: 'Add random',
                        Manual_verb_selection: 'Manual verb selection: please select verbs from list or write them in manually. It is possible to use both selection types at the same time.',
                        Choose_tense_and_go: 'Choose tense below and press "Go!" button.',
                        Go: 'Go!',
                        Select: 'Select...',
                    },
                    contact: {
                        Title: 'Didn\'t find the right verb? Have any troubles? Please contact us!',
                        Success: 'Thanks for your opinion!',
                        Error: 'Oops...! some problem!',
                        Name: 'Name',
                        Email: 'E-Mail',
                        Message: 'Message',
                        SendMessage: 'Send message',
                    },
                    verbs: {
                        Title: 'Verbo Go Conjugator',
                        Description: 'Select or enter infinitive form of verb and get all conjugation forms!',
                        Select: 'Select...',
                        Synonyms: 'Synonyms',
                    },
                    tests: {
                        FinishTest: 'Finish Test',
                        ShowCorrectAnswers: 'Show correct answers',
                        StartAgain: 'Start Again',
                        YourResult: 'Your results',
                        CorrectAnswers: 'Correct answers',
                    },
                }
            },
            ru: {
                translation: {
                    menu: {
                        Trainer: 'Тренажер',
                        Conjugator: 'Справочник',
                        Grammar: 'Грамматика',
                        Tests: 'Тесты',
                        Privacy: 'Политика конфиденциальности',
                    },
                    footer: {
                        copyright: 'Все права защищены.'
                    },
                    game: {
                        initial_header: 'Добро пожаловать в тренажер глпголов Verbo Go!',
                        initial_text: 'Всего 10 минутв день значительно расширят ваш словарный запас и знание наиболее часто употребляемых форм глаголов.<br /> Чтобы начать, пожалуйста, следуйте инструкции ниже:',
                        game_header: 'Давайте попрактикуемся!',
                        game_text: '1. Введите глагол в правильной форме и нажмите кнопку "$t(game.Check)".<br /> 2. Если вам нужна помощь, пожалуйста, нажмите кнопку "$t(game.Help_me)".<br />3. Чтобы изменить выбор глаголов, нажмите "$t(game.Finish)".',
                        statistic_header: 'Отличная работа! Оцените свои результаты и попробуйте снова!',
                        Tense: 'Время',
                        Example: 'Пример',
                        Your_verbs: 'Ваши глаголы',
                        Check: 'Проверить',
                        Correct_answer: 'Правильно!',
                        Error: 'Ошибка!',
                        Next_word: 'Следующее слово',
                        Help_me: 'Помощь',
                        Finish: 'Закончить',
                        Start_again: 'Начать заново',
                        Verbs: 'Глаголы',
                        Attempts: 'Попытки',
                        Correct: 'Верные',
                        Errors: 'Ошибок',
                        Skipped: 'Пропущено',
                        Success: 'Удачные попытки',
                        rule_1: 'Вперед! Выберите параметры  и время глагола ниже!',
                        rule_2: 'Любой глагол: для выбора глаголов случайным обрахом проверьте параметры и количество (от 1 до 10) ниже и нажмите кнопку "$t(game.Add_random)".',
                        Add_random: 'Добавить любой',
                        Manual_verb_selection: 'Выбор глагола вручную: выберите глаголы из списка или введите их вручную. Можно использовать оба типа введения одновременно.',
                        Choose_tense_and_go: 'Выберите время ниже и нажмите кнопку "$t(game.Go)"',
                        Go: 'Начать!',
                        Select: 'Выбрать...',
                    },
                    contact: {
                        Title: 'Не нашли нужное слово? Возникли трудности? Пожалуйста, свяжитесь с нами!',
                        Success: 'Спасибо за ваше мнение',
                        Error: 'Произошла ошибка!',
                        Name: 'Имя',
                        Email: 'Эл.почта',
                        Message: 'Собщение',
                        SendMessage: 'Отправить сообщение',
                    },
                    verbs: {
                        Title: 'Справочник глаголов Verbo Go',
                        Description: 'Выберите или введите начальную форму глагола и получите список всех его временных форм!',
                        Select: 'Выбрать...',
                        Synonyms: 'Синонимы',
                    },
                    tests: {
                        FinishTest: 'Закончить тест',
                        ShowCorrectAnswers: 'Показать правильные ответы',
                        StartAgain: 'Начать заново',
                        YourResult: 'Ваш результат',
                        CorrectAnswers: 'Правильные ответы',
                    },
                }
            }
        }
    });

export default i18n;