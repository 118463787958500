export function PreteritoImperfectoNameEn() {
	return "Pretérito Imperfecto";
}

export function PreteritoImperfectoEn() {
	return <div>
		<h4>Pretérito Imerfecto</h4>
		        <p>The Pretérito Imperfecto is used to describe past actions that occurred regularly or continued over a specific period in the past. This tense can also be used to describe the background or circumstances in which another action took place.</p>
        <h5>Examples:</h5>
		<blockquote>
			<p>Todos los días yo caminaba por el parque. (Every day I walked through the park.)</p>
			<p>Mientras yo leía, mi hermano miraba la televisión. (While I was reading, my brother was watching TV.)</p>
			<p>Cuando era joven, viajaba mucho. (When I was young, I traveled a lot.)</p>
		</blockquote>
        <p>Pretérito Imperfecto is used in the following cases:</p>
        <blockquote>
            <p>- to describe past actions that are not limited to a specific moment in time;</p>
            <p>- to express habits in the past;</p>
            <p>- to describe circumstances or backgrounds against which another action took place;</p>
            <p>- in narratives to create an atmosphere of the past;</p>
            <p>- to describe people, places, objects, and situations in the past, as well as to express age, time, weather, and emotions.</p>
        </blockquote>
		<p>To form the Pretérito Imperfecto, it is necessary to use the base form of the verb and add the appropriate endings. The endings differ for verbs of the 1st <strong>(-ar)</strong>, 2nd <strong>(-er)</strong>, and 3rd <strong>(-ir)</strong> conjugations:</p>
		<blockquote>
			<p>-  verbs ending in <strong>-ar</strong>: <br/><i>-aba, -abas, -aba, -ábamos, -abais, -aban;</i></p>
			<p>-  verbs ending in  <strong>-er</strong> и <strong>-ir</strong>: <br/><i>-ía, -ías, -ía, -íamos, -íais, -ían.</i></p>
		</blockquote>
		<br/>
		<p>Examples of forming the Pretérito Imperfecto</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						hablar <span className="red">(1)</span> <p><i>(to speak)</i></p>					</td>
					<td className="head-item">
						comer <span className="red">(2)</span> <p><i>(to eat)</i></p>
					</td>
					<td className="head-item">
						vivir <span className="red">(3)</span> <p><i>(to live)</i></p>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">habl<span className="red">aba</span></td>
					<td className="body-item">com<span className="red">ía</span></td>
					<td className="body-item">viv<span className="red">ía</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">habl<span className="red">abas</span></td>
					<td className="body-item">com<span className="red">ías</span></td>
					<td className="body-item">viv<span className="red">ías</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">habl<span className="red">aba</span></td>
					<td className="body-item">com<span className="red">ía</span></td>
					<td className="body-item">viv<span className="red">ía</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">habl<span className="red">ábamos</span></td>
					<td className="body-item">com<span className="red">íamos</span></td>
					<td className="body-item">viv<span className="red">íamos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">habl<span className="red">abais</span></td>
					<td className="body-item">com<span className="red">íais</span></td>
					<td className="body-item">viv<span className="red">íais</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">habl<span className="red">aban</span></td>
					<td className="body-item">com<span className="red">ían</span></td>
					<td className="body-item">viv<span className="red">ían</span></td>
				</tr>
			</tbody>
		</table>
		<br/>	
		<p>Reflexive verbs, for example, <strong>llamarse, terminarse, olvidarse</strong>, as usual, are conjugated with a reflexive pronoun. In the case of Pretérito Imperfecto, it looks as follows:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						llamarse <i>(называться)</i></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">me</span> llamaba</td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">te</span> llamabas</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">se</span> llamaba</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">nos</span> llamábamos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">os</span> llamabais</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">se</span> llamaban</td>
				</tr>
			</tbody>
		</table>
         <p>Pretérito Imperfecto is characterized by a very small number of irregular forms, making it one of the simpler tenses in the Spanish language. Nevertheless, there are a few verbs that change in an irregular manner:</p>
        
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">ser <p><i>(to be)</i></p></td>
					<td className="head-item">ir <p><i>(to go)</i></p></td>
					<td className="head-item">ver <p><i>(to watch)</i></p ></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">era</td>
					<td className="body-item">iba</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">eras</td>
					<td className="body-item">ibas</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">era</td>
					<td className="body-item">iba</td>
					<td className="body-item">veía</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">eramos</td>
					<td className="body-item">ibamos</td>
					<td className="body-item">veíamos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">erais</td>
					<td className="body-item">ibáis</td>
					<td className="body-item">veías</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">eran</td>
					<td className="body-item">iban</td>
					<td className="body-item">veían</td>
				</tr>
			</tbody>
		</table>
          <h5>Forms "había" and "hubo"</h5>
        <p>There is often confusion in the use of <strong>había</strong> (form of <i>haber</i> in Imperfecto) and <strong>hubo</strong> (form of <i>haber</i> in Indefinido) to express "was" or "existed". <strong>Había</strong> is used to describe past background or context, while <strong>hubo</strong> is used to indicate a specific event.</p>

	
		<h5>Words - indicators</h5>
		<p>These words or phrases can help determine when it is appropriate to use Pretérito Imperfecto, especially for describing past actions, habits, or circumstances.</p>
		<blockquote>
			<p><strong>siempre</strong> - always (indicates a habitual action in the past)</p>
            <p><strong>todos los días</strong> - every day (denotes regular actions)</p>
            <p><strong>cada año</strong> - every year (for describing annual events or actions)</p>
            <p><strong>mientras</strong> - while (used to indicate simultaneity of actions in the past)</p>
            <p><strong>en aquella época</strong> - at that time (to denote a time period in the past)</p>
            <p><strong>antes</strong> - before (refers to actions or habits that were common in the past)</p>
            <p><strong>nunca</strong> - never (can be used to express the absence of an action in the past)</p>
            <p><strong>a menudo</strong> - often (indicates the frequency of actions in the past)</p>
            <p><strong>de vez en cuando</strong> - occasionally (for irregular or random actions in the past)</p>
            <p><strong>muchas veces</strong> - many times (emphasizes the repetition of an action in the past)</p>

		</blockquote>	
	</div>;
}