import React, { useState, useEffect } from 'react';
import Start from './start'
import ContactForm from '../contactForm'
import SpecialChars, { UseFocus } from '../specialChars/specialChars'
import { useTranslation, Trans } from 'react-i18next';

import './game.css'

export default function Game() {
    const { t, i18n } = useTranslation();

    const [inputRef, setInputFocus] = UseFocus();
    const [loading, setLoading] = useState(true);
    const [showStatistic, setShowStatistic] = useState(false);
    const [game, setGame] = useState(null);
    const [verbValue, setVerbValue] = useState('');
    const [hint, setHint] = useState(false);

    useEffect(() => {
        checkGame(true, false, false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputOnChange = (event) => {
        var text = event.target.value.toLowerCase();
        text = text.replace('/a', '\xE1'); // �
        text = text.replace('/e', '\xE9'); // �
        text = text.replace('/i', '\xED'); // �
        text = text.replace('/o', '\xF3'); // �
        text = text.replace('/u', '\xFA'); // �
        text = text.replace('"u', '\xFC'); // �
        text = text.replace('~n', '\xF1'); // �
        setVerbValue(text);
        setHint(false);
    }

    const inputOnKeyDown = (event) => {
        if (event.key === 'Enter') {
            checkGame(false, false, true);
        }
    }

    const renderGame = (game) => {
        const pronouns = game.section === 'Participio' || game.section === 'Gerundio' ? '' : game.pronouns;
        return (
            <div>
                <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('game.Tense')}: {game.tense}</strong></h4>
                <p className="mbr-text mbr-fonts-style display-7"><strong>{t('game.Example')}:</strong> {game.example}</p>
                <p className="mbr-text mbr-fonts-style display-7"><strong>{t('game.Your_verbs')}:</strong> {game.verbList}</p>
                <table className="gameForm display-7">
                    <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td colSpan="2" id="translation">
                                <div style={{ "width": "100%", "clear": "both" }}>
                                    <div style={{ "width": "50%", "float": "left", color: "red", fontWeight: 'bold' }}>{game.verb}</div>
                                    <div style={{ "width": "50%", "float": "right", "textAlign": "right" }}>
                                        {game.translation}&nbsp;
                                        <span style={{ "color": "silver" }}>({i18n.resolvedLanguage})</span>
                                    </div>
                                </div>
                                
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" className="gameColumn-13">
                                <SpecialChars inputFocus={setInputFocus} inputRef={inputRef} shiftPressed={() => false} />
                            </td>
                        </tr>
                        <tr>
                            <td className="gameColumn-1">{pronouns}</td>
                            <td className="gameColumn-2">
                                <input autoFocus className="form-control" maxLength="100" value={verbValue}
                                    ref={inputRef}
                                    onChange={(e) => inputOnChange(e)}
                                    onKeyDown={(e) => inputOnKeyDown(e)}
                                />
                            </td>
                            <td className="gameColumn-3">
                                <button className="btn btn-primary display-4" onClick={() => checkGame(false, false, true)}>{t('game.Check')}</button>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td colSpan="2">
                                {game.showCorrect ? t('game.Correct_answer') : ""}
                                {game.showError ? t('game.Error') + " : " + game.value : ""}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" className="gameColumn-13">
                                <button className="btn btn-white display-4" onClick={() => checkGame(false, true, false)}>{t('game.Next_word')}</button> &nbsp;
                                <button className="btn btn-white display-4" onClick={() => { setVerbValue(game.value); setInputFocus(); setHint(true); }}>{t('game.Help_me')}</button> &nbsp;
                                <button className="btn btn-secondary display-4" onClick={finishGame}>{t('game.Finish')}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const startAgain = () => {
        setShowStatistic(false);
    }

    const renderStatistics = (stats) => {
        return (
            <>
                <button className="btn btn-primary display-4" onClick={startAgain}>{t('game.Start_again')}</button>
                <table className='statistic table'>
                    <thead>
                        <tr className='table-heads'>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Verbs')}</th>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Attempts')}</th>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Correct')}</th>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Errors')}</th>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Skipped')}</th>
                            <th className="head-item mbr-fonts-style display-7">{t('game.Success')}, %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.map((stat) => {
                            const total = stat.correct + stat.skipped + stat.hints + stat.errors;
                            const successPercent = Math.round(total > 0 ? stat.correct / total * 100 : 0);
                                return <tr key={stat.word}>
                                    <td className="body-item mbr-fonts-style display-7">{stat.word}</td>
                                    <td className="body-item mbr-fonts-style display-7">{total}</td>
                                    <td className="body-item mbr-fonts-style display-7">{stat.correct}</td>
                                    <td className="body-item mbr-fonts-style display-7">{stat.errors}</td>
                                    <td className="body-item mbr-fonts-style display-7">{stat.skipped}</td>
                                    <td className="body-item mbr-fonts-style display-7">{successPercent}</td>
                                </tr>
                        })}
                    </tbody>
                </table>
                <button className="btn btn-primary display-4" onClick={startAgain}>{t('game.Start_again')}</button>
            </>
        );
    }

    const startGame = (body) => {
        setLoading(true);

        fetch(`gamet/start`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(res => res.json())
        .then(json => {
            setGame(json);
            setInputFocus();
            setVerbValue('');
            setLoading(false);
            if (document.getElementById("translation")) {
                document.getElementById("translation").scrollIntoView();
            }
        })
        .catch(error => console.error(error));
    }

    const checkGame = (init, skip, check) => {
        fetch(`gamet/check`, {
            method: 'POST',
            body: JSON.stringify({ init, skip, check, hint, verbValue }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;
        })
        .then(res => res.json())
        .then(json => {
            setGame(json);
            setInputFocus();
            setLoading(false);
            setHint(false);
            setVerbValue('');
            if (document.getElementById("translation")) {
                document.getElementById("translation").scrollIntoView();
            }
        })
        .catch(error => console.error(error));
    }

    const finishGame = () => {
        setLoading(true);

        fetch('gamet/finish')
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then(json => {
                setGame(json);
                setShowStatistic(true);
                setLoading(false);
            })
            .catch(error => console.error(error));
    }

    let contents = loading
        ? <p><em>Loading...</em></p>
        :
        (
            game.verb
                ? renderGame(game)
                : showStatistic
                    ? renderStatistics(game.statistics)
                    : <Start startGame={startGame} />
                
        );

    return (
        <div>
            <section className="paddingTop32 paddingBottom48">
                <div className="row justify-content-center">
                    {!loading && game.verb
                    ? <div className="col-md-12 col-lg-12">
                            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('game.game_header')}</strong></h4>
                            <p className="mbr-text mbr-fonts-style display-7">
                                <Trans i18nKey="game.game_text" />
                            </p>
                        </div>
                    : showStatistic
                        ? <div className="col-md-12 col-lg-12">
                            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('game.statistic_header')}</strong></h4>
                        </div>
                        : <div className="col-md-12 col-lg-12">
                            <h4 className="mbr-section-subtitle mbr-fonts-style mb-4 display-7"><strong>{t('game.initial_header')}</strong></h4>
                            <p className="mbr-text mbr-fonts-style display-7">
                                <Trans i18nKey="game.initial_text" />
                            </p>
                        </div>
                    }
                </div>
            </section>
            <section className="paddingBottom48">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <div className="checker">{contents}</div>
                    </div>
                </div>
            </section>
            <ContactForm />
        </div>
    );
}
