export function PreteritoPerfectoNameRu() {
	return "Pretérito Perfecto Compuesto";
}

export function PreteritoPerfectoRu() {
	return <div>
		<h4>Pretérito Perfecto Compuesto</h4>
		<p>Pretérito Perfecto Compuesto аналогично времени Present Perfect в английской грамматике. Обычно это время используется при повествовании о действиях, которые произошли в недавнем прошлом. Оно может описывать:</p>
		<blockquote>
			<p>- действия или события в прошлом, которые недавно завершились;</p>
			<p>- действия или события в прошлом, которые начались в прошлом и продолжаются до сих пор;</p>
			<p>- завершённые действия, влияющие на настоящее или будущее;</p>
			<p>- личный опыт, который произошёл (или не произошёл).</p>
		</blockquote>
		<p>Pretérito Perfecto Compuesto — это сложное время (Compuesto), образованное из двух глаголов: глагола <strong>haber</strong> в простом настоящем времени + <strong>причастие</strong> прошедшего времени (Verbo Participio). </p>
		<blockquote><span className="red"><strong>Haber (настоящее время) + Причастие (прошлое время)</strong></span></blockquote>
		<p>Только глагол <strong>haber</strong> спрягается; причастие прошедшего времени остаётся неизменным независимо от лица.</p>
		<p>Спряжение глагола <strong>haber</strong> в настоящем времени:</p>
	
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>

				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">he</span></td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">has</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">ha</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">hemos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">habéis</span></td>
					</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">han</span></td>
				</tr>
			</tbody>
		</table>
		<br/>
		<p>Причастие прошедшего времени (Participio Pasado) образуется согласно этой таблице:</p>
		
			<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">Спряжение</td>
					<td className="head-item">Окончание</td>
					<td className="head-item">Меняется на ...</td>
					<td className="head-item">Пример</td>
					<td className="head-item"> </td>
				</tr>
			</thead>
			<tbody>
				<tr>					
					<td className="body-item"><strong><br/><i>Первое спряжение</i></strong></td>
					<td className="body-item"><strong><br/>-ar</strong></td>
					<td className="body-item"><span className="red"><br/>-ado</span></td>
					<td className="body-item">hablar<br />terminar<br />caminar</td>
					<td className="body-item">habl<span className="red">ado</span><br />termin<span className="red">ado</span><br />camin<span className="red">ado</span></td>
				</tr>
				<tr>					
					<td className="body-item"><strong><br /><i>Второе спряжение</i></strong></td>
					<td className="body-item"><strong><br />-er</strong></td>
					<td className="body-item"><span className="red"><br />-ido</span></td>
					<td className="body-item">beber<br />comer<br />aprender</td>
					<td className="body-item">beb<span className="red">ido</span><br />com<span className="red">ido</span><br />aprend<span className="red">ido</span></td>
				</tr>
				<tr>					
					<td className="body-item"><strong><br/><i>/Третье спряжение</i></strong></td>
					<td className="body-item"><strong><br />-ir</strong></td>
					<td className="body-item"><br /><span className="red">-ido</span></td>
					<td className="body-item">salir <br />decidir<br />venir</td>
					<td className="body-item">sal<span className="red">ido</span><br /> decid<span className="red">ido</span><br /> ven<span className="red">ido</span></td>
				</tr>
				</tbody>
		</table>
		<br />
		В итоге сочетание (Haber (Настоящее время) + Participle (Прошлое время))формируется следующим образом:
				<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						hablar <span className="red">(1)</span></td>
					<td className="head-item">
						comer <span className="red">(2)</span></td>
					<td className="head-item">
						sentir <span className="red">(3)</span></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">he</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">he</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">he</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">has</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">has</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">has</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">ha</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">ha</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">ha</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">hemos</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">hemos</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">hemos</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">habéis</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">habéis</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">habéis</span> sent<span className="red">ído</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">han</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">han</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">han</span> sent<span className="red">ido</span></td>
				</tr>
			</tbody>
		</table>
		<br/>
		<p>В Pretérito Perfecto Compuesto глаголы <strong>estar</strong> и <strong>ser</strong> регулярны и спрягаются как <strong>estado</strong>  и <strong>sido</strong>.</p>
		<p>Причастие в этом случае не меняется ни по роду, ни по числу и всегда заканчивается на <strong><span className="red">o</span></strong>. Например:</p>
		
		<blockquote>
			<p>Carmen ha comid<span className="red">o</span> esta mañana–<strong> правильно</strong></p>
			<p>Carmen ha comid<span className="red">a</span> esta mañana– <span className="red">нет</span></p>
			<p>Jorge y Elena han comid<span className="red">o</span> esta mañana – <strong> правильно</strong></p>
			<p>Jorge y Elena han comid<span className="red">os</span> esta mañana – <span className="red">нет</span></p>
		</blockquote>
		<p>Некоторые причастия в испанском языке неправильные и не спрягаются согласно основным правилам. Их просто нужно запомнить.</p>
		
		<blockquote>
			<p><strong>abrir</strong> (открыть) - <strong>abierto</strong> </p>
			<p><strong>ir</strong> (идти) - <strong>ido</strong> </p>
			<p><strong>hacer</strong> (делать) - <strong>hecho</strong> </p>
			<p><strong>freir</strong> (жарить) - <strong>frito</strong> </p>
			<p><strong>ver</strong> (смотреть) - <strong>visto</strong> </p>
			<p><strong>decir</strong> (сказать) - <strong>dicho</strong> </p>
			<p><strong>escribir</strong> (писать) - <strong>escrito</strong> </p>
			<p><strong>volver</strong> (вернуть) - <strong>vuelto</strong> </p>
			<p><strong>romper</strong> (сломать) - <strong>roto</strong> </p>
			<p><strong>descubrir</strong> (исследовать) - <strong>descubierto</strong> </p>
			<p><strong>poner</strong> (класть) - <strong>puesto</strong> </p>
			<p><strong>resolver</strong> (обещать) - <strong>resuelto</strong> </p>
			<p><strong>morir</strong> (умирать) - <strong>muerto</strong> </p>	
		</blockquote>
		<p><strong>Примеры Pretérito Perfecto:</strong></p>
		<blockquote>
			<p><i>Lola ha vivido en Madrid toda su vida.</i></p>
			<p><i>Este año Luis ha venido a Londres.</i></p>
			<p><i>He dormido todo el día. </i></p>
			<p><i>Ya he visto esta película dos veces.</i></p>
		</blockquote>
		
		<p>Рефлексивные глаголы, например, llamarse, terminarse, olvidarse, как всегда, спрягаются с рефлексивным местоимением. В случае с Preterito Perfecto это будет выглядеть следующим образом:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						olvidarse <i>(to forget)</i></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">me</span> he olvidado</td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">te</span> has olvidado</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">se</span> ha olvidado</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">nos</span> hemos olvidado</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">os</span> habéis olvidado</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">se</span> han olvidado</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>Слова - индикаторы</h5>
		<p>В предложениях часто используются наречия и фразы, указывающие на  Present Perfect Tense. Запомните их, чтобы определять Preterito Perfecto Compuesto.</p>
		<blockquote>
			<p><strong>hoy</strong> - сегодня</p>
			<p><strong>ya</strong> - уже</p>
			<p><strong>todavia</strong> - все еще</p>
			<p><strong>nunca</strong> - никогда</p>
			<p><strong>últimamente</strong> - в последнее время</p>
			<p><strong>esta mañana (esta tarde, esta noche)</strong> - этим утром (сегодня днем, этим вечером)</p>
			<p><strong>esta semana, este mes, este año</strong> - на этой неделе, в этом месяце, в этом году</p>
			<p><strong>este siglo</strong> - в этом столетии</p>
			<p><strong>desde el año pasado / mes / semana</strong> - с прошлого года / месяца /недели</p>
		</blockquote>	
	</div>;
}