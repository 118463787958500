import React, { useEffect } from 'react';
import './pillsTab.css'
import * as bootstrap from 'bootstrap';

export default function PillsTab(props) {

    useEffect(() => {
        var tabSelect = document.getElementById("tabSelect");
        var tabEl = document.querySelectorAll('button[data-bs-toggle="tab"]')
        tabEl.forEach(box => {
            box.addEventListener('shown.bs.tab', function (event) {
                tabSelect.value = event.target.dataset.index;
            })
        })
    }, [props.tabs]);

    const onTabChange = (event) => {
        var firstTabEl = document.querySelector('#v-pills-tab button:nth-of-type(' + event.target.value + ')')
        var firstTab = new bootstrap.Tab(firstTabEl)

        firstTab.show()
    }

    const generateSelect = () => {
        return <select id="tabSelect" onChange={onTabChange}>
            {props.tabs.map((key, i) => <option key={i} value={i + 1}>{props.tabFunc(key)}</option>)}
        </select>
    }

    const generateTabs = () => {
        var selected = true;

        return props.tabs.map((key, i) => {
            var targetId = 'id_' + i;
            var tabId = 'id_' + i + '_tab';
            var style = selected ? "nav-link active" : "nav-link";
            var sel = selected;
            selected = false;
            return (
                <button key={i} className={style} id={tabId} data-index={i + 1} data-bs-toggle="tab" data-bs-target={'#' + targetId} type="button" role="tab" aria-controls={targetId} aria-selected={sel} >
                    {props.tabFunc(key)}
                </button>
            );
        }
        );
    }

    const generateBody = () => {
        var once = false;

        return props.body.map((key, i) => {
            var targetId = 'id_' + i;
            var tabId = 'id_' + i + '_tab';
            var style = once ? "tab-pane fade" : "tab-pane fade show active";
            once = true;
            return <div key={i} className={style} id={targetId} role="tabpanel" aria-labelledby={tabId}>
                {props.bodyFunc(key)}
            </div>
        }
        );
    }

	return <>
        <div className="tabSelect">
            {generateSelect()}
            <br />
            <br />
        </div>
        <div className="d-flex align-items-start">
            <div className="nav flex-column nav-left nav-tabs me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                {generateTabs()}
            </div>
            <div className="tab-content" id="v-pills-tabContent">
                {generateBody()}
            </div>
        </div>
	</>
}