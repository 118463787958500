import React, { useState } from 'react';
import TestInput from './testInput';
import { useTranslation } from 'react-i18next';
import './tests.css'

export default function Tests(props) {
    const { t } = useTranslation();
    const [inputs, setInputs] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [showAnswers, setShowAnswers] = useState(false);
    const namePrefix = "Test_";

    const generateTests = (tests) => {
        return tests.map((test, i) => {
            var sentence = test.sentence.split("{}");
            var name = namePrefix + i;

            if (inputs[name] === undefined) {
                setInputs(prev => {
                    return { ...prev, [name]: '' };
                });
            }

            return <div key={i}>
                <span>{sentence[0].trim()} </span>
                <span>
                    {
                        test.options
                            ? <select name={name} className="testBox" onChange={handleChange}>
                                <option></option>
                                {test.options.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
                            </select>
                            :
                            <span className="input-container">
                                <TestInput name={name} handleChange={handleChange} />
                             </span>
                    }
                </span>
                <span> {sentence[1].trim()}</span>
            </div>
        });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs(prev => {
            return { ...prev, [name]: value };
        });
    }

    const isFormValid = () => {
        return Object.keys(inputs).map(key => inputs[key]).find((element) => {
            return element === '';
        }) === '';
    }

    const renderTests = () => {
        return <>
            {generateTests(props.tests)}
            <br />
            <button className="btn btn-primary" onClick={() => setShowResult(true)} disabled={isFormValid()}>{t('tests.FinishTest')}</button>
        </>
    }

    const renderResult = () => {
        var keys = Object.keys(inputs);
        var correct = keys.map(key => {
            var index = key.substring(namePrefix.length);
            return props.tests[Number(index)].correct === inputs[key] ? 1 : 0;
        })
        .reduce((a, v) => a = a + v, 0);
        return <>
            <div><strong>{t('tests.YourResult')}:</strong></div>
            <div>{t('tests.CorrectAnswers')}:  {correct} /{keys.length}</div>
            <br />
            <button className="btn btn-primary" onClick={startAgain}>{t('tests.StartAgain')}</button>
            <button className="btn btn-primary" onClick={() => setShowAnswers(true)}>{t('tests.ShowCorrectAnswers')}</button>
        </>
    }

    const renderAnswers = () => {
        return <>
            {generateAnswers(props.tests)}
            <br />
            <button className="btn btn-primary" onClick={startAgain}>{t('tests.StartAgain')}</button>
        </>
    }

    const startAgain = () => {
        setInputs({});
        setShowResult(false);
        setShowAnswers(false);
    }

    const generateAnswers = (tests) => {
        return tests.map((test, i) => {
            var sentence = test.sentence.split("{}");
            var name = namePrefix + i;
            

            return <div key={i}>
                <span>{sentence[0].trim()} </span>
                <span className="stripedRed">{inputs[name] !== undefined && inputs[name] !== test.correct ? inputs[name] : ''}</span>
                <span className="green"> {test.correct}</span>
                <span> {sentence[1].trim()}</span>
            </div>
        });
    }

    return <>
        <h4>{props.title}</h4>
        {showResult
            ? (showAnswers
                ? renderAnswers()
                : renderResult()
            )
            : renderTests()}
        <br />
        <br />
    </>
}