import React, { useRef } from 'react';

export default function SpecialChars(props) {

    const insertChar = (event) => {
        let currectPosition = props.inputRef.current.selectionStart;
        let textBeforeCursorPosition = props.inputRef.current.value.substring(0, currectPosition)
        let textAfterCursorPosition = props.inputRef.current.value.substring(currectPosition, props.inputRef.current.value.length)
        props.inputRef.current.value = textBeforeCursorPosition + event.currentTarget.innerText + textAfterCursorPosition
        props.inputFocus();
        props.inputRef.current.selectionStart = currectPosition + 1;
        props.inputRef.current.selectionEnd= currectPosition + 1;
    }

    return <>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xC1' : '\xE1'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xC9' : '\xE9'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xCD' : '\xED'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xD3' : '\xF3'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xDA' : '\xFA'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xDC' : '\xFC'}</button>
        <button className="btn btn-white" onClick={(e) => insertChar(e)} onFocus={props.focused}>{props.shiftPressed() ? '\xD1' : '\xF1'}</button>
	</>
}

export function UseFocus() {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}