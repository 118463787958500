import Game from "./components/game/game";
import Verbs from "./components/verbs/verbs";
import TenseInfo from "./components/TenseInfo/tenseInfo";
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy";
import Tests from "./components/tests/tests";

const AppRoutes = [
    {
        index: true,
        element: <Game />
    },
    {
        path: '/entrenador',
        element: <Game />
    },
    {
        path: '/conjugador',
        element: <Verbs />
    },
    {
        path: '/grammar',
        element: <TenseInfo />
    },
    {
        path: '/privacy',
        element: <PrivacyPolicy />
    },
    {
        path: '/tests',
        element: <Tests />
    }
];

export default AppRoutes;
