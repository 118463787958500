export function PresentedeIndicativoNameEn() {
	return "Presente de Indicativo";
}

export function PresentedeIndicativoEn() {
	return <div>
		<h4>Presente de Indicativo</h4>
		<p>Presente de Indicativo in Spanish is similar to the Simple Present Tense in English grammar. Usually this tense is used to speak about actions in the present or near future. It describes facts, routine or actions that are repeated in the present as well as permanent situations.</p>
		<h5>Examples:</h5>
		<blockquote>
			<p>Él vive en Madrid.</p>
			<p>Carla trabaja en un hospital desde hace tres años.</p>
			<p>Pablo y yo desayunamos a las 9 de la mañana.</p>
			<p>El próximo mes voy a París.</p>
		</blockquote>
		<p>There are 3 verb conjugations in Spanish. To determine the conjugation of a verb is very simple, just look at its ending in the indefinite form: <strong>-ar</strong>, <strong>-er</strong> or <strong>-ir</strong>:</p>
		<blockquote>
			<p><strong>1st conjugation (-ar): habl<span className="red"><strong>ar</strong></span>, trabaj<span className="red"><strong>ar</strong></span>, lleg<span className="red"><strong>ar</strong></span>, cerr<span className="red"><strong>ar</strong></span>.</strong></p>
			<p><strong>2nd conjugation(-er): com<span className="red"><strong>er</strong></span>, sab<span className="red"><strong>er</strong></span>, le<span className="red"><strong>er</strong></span>, pon<span className="red"><strong>er</strong></span>.</strong></p>
			<p><strong>3rd conjugation (-ir): viv<span className="red"><strong>ir</strong></span>, escrib<span className="red"><strong>ir</strong></span>, abr<span className="red"><strong>ir</strong></span>, dec<span className="red"><strong>ir</strong></span>.</strong></p>
		</blockquote>
		<p>The form of the verb in the Presente de Indicativo will depend on the conjugation. Also will need to know which person and is it singular or plural. Depending on this, the verb will have 6 main forms.</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						hablar <span className="red">(1)</span> <p><i>(to speak)</i></p>					</td>
					<td className="head-item">
						comer <span className="red">(2)</span> <p><i>(to eat)</i></p>
					</td>
					<td className="head-item">
						escribir <span className="red">(3)</span> <p><i>(to write)</i></p>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">habl<span className="red">o</span></td>
					<td className="body-item">com<span className="red">o</span></td>
					<td className="body-item">escrib<span className="red">o</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">habl<span className="red">as</span></td>
					<td className="body-item">com<span className="red">es</span></td>
					<td className="body-item">escrib<span className="red">es</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">habl<span className="red">a</span></td>
					<td className="body-item">com<span className="red">e</span></td>
					<td className="body-item">escrib<span className="red">e</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">habl<span className="red">amos</span></td>
					<td className="body-item">com<span className="red">emos</span></td>
					<td className="body-item">escrib<span className="red">imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">habl<span className="red">áis</span></td>
					<td className="body-item">com<span className="red">éis</span></td>
					<td className="body-item">escrib<span className="red">ís</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">habl<span className="red">an</span></td>
					<td className="body-item">com<span className="red">en</span></td>
					<td className="body-item">escrib<span className="red">en</span></td>
				</tr>
			</tbody>
		</table>
		<br />
		<p>To conjugate Present Tense, just remove the endings of the infinitive form and add the ending according to the table. This rule only applies to regular verbs.</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item"><span className="red"><i>(1)</i></span></td>
					<td className="head-item"><span className="red"><i>(2)</i></span></td>
					<td className="head-item"><span className="red"><i>(3)</i></span></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">-o</span></td>
					<td className="body-item"><span className="red">-o</span></td>
					<td className="body-item"><span className="red">-o</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">-as</span></td>
					<td className="body-item"><span className="red">-es</span></td>
					<td className="body-item"><span className="red">-es</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">-a</span></td>
					<td className="body-item"><span className="red">-e</span></td>
					<td className="body-item"><span className="red">-e</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">-amos</span></td>
					<td className="body-item"><span className="red">-emos</span></td>
					<td className="body-item"><span className="red">-imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">-áis</span></td>
					<td className="body-item"><span className="red">-éis</span></td>
					<td className="body-item"><span className="red">-ís</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">-an</span></td>
					<td className="body-item"><span className="red">-en</span></td>
					<td className="body-item"><span className="red">-en</span></td>
				</tr>
			</tbody>
		</table>
		<br />
		<p>Reflexive verbs, for example, llamarse, terminarse, olividarse, are conjugated with a reflexive pronoun. To choose a reflexive pronoun you need to know the person and whether it is singular or plural. The reflexive pronoun comes before the verb.</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">llamarse<p><i>(to be called)</i></p></td>
					<td className="head-item">terminarse <p><i>(to be finished)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">me llam<span className="red">o</span></td>
					<td className="body-item">me termin<span className="red">o</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">te llam<span className="red">as</span></td>
					<td className="body-item">te termin<span className="red">asv</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">se llam<span className="red">a</span></td>
					<td className="body-item">se termin<span className="red">a</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">nos llam<span className="red">amos</span></td>
					<td className="body-item">nos termin<span className="red">amos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">os llam<span className="red">áis</span></td>
					<td className="body-item">os termin<span className="red">áis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">se llam<span className="red">an</span></td>
					<td className="body-item">se termin<span className="red">an</span></td>
				</tr>
			</tbody>
		</table>
		<br />
		<p>Some verbs in Spanish are irregular and not conjugated according to the previous rules. You just need to remember it.</p>
		<h5> SER, ESTAR, HABER</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">ser <p><i>(to be)</i></p></td>
					<td className="head-item">estar <p><i>(to be)</i></p></td>
					<td className="head-item">haber <p><i>(to have)</i></p ></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">soy</td>
					<td className="body-item">estoy</td>
					<td className="body-item">he</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">eres</td>
					<td className="body-item">estás</td>
					<td className="body-item">has</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">es</td>
					<td className="body-item">está</td>
					<td className="body-item">ha</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">somos</td>
					<td className="body-item">estamos</td>
					<td className="body-item">hemos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">sois</td>
					<td className="body-item">estáis</td>
					<td className="body-item">habéis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">son</td>
					<td className="body-item">están</td>
					<td className="body-item">han</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>IR, SABER, DAR</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">ir  <p><i>(to go)</i></p></td>
					<td className="head-item">saber  <p><i>(to know)</i></p></td>
					<td className="head-item">dar  <p><i>(to do)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">voy</td>
					<td className="body-item">sé</td>
					<td className="body-item">doy</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">vas</td>
					<td className="body-item">sabes</td>
					<td className="body-item">das</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">ve</td>
					<td className="body-item">sabe</td>
					<td className="body-item">da</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">vamos</td>
					<td className="body-item">sabemos</td>
					<td className="body-item">damos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">vais</td>
					<td className="body-item">sabéis</td>
					<td className="body-item">dais</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">van</td>
					<td className="body-item">saben</td>
					<td className="body-item">dan</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>DECIR, PEDIR (-e- → -i-)</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">decir <p><i>(to say)</i></p></td>
					<td className="head-item">pedir <p><i>(to ask)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr><td className="body-item">yo</td>
					<td className="body-item">d<span className="red">i</span>go</td>
					<td className="body-item">p<span className="red">i</span>do</td>
				</tr><tr><td className="body-item">tú</td>
					<td className="body-item">d<span className="red">i</span>ces</td>
					<td className="body-item">p<span className="red">i</span>des</td>
				</tr><tr><td className="body-item">él/ella/Vd.</td>
					<td className="body-item">d<span className="red">i</span>ce</td>
					<td className="body-item">p<span className="red">i</span>de</td>
				</tr><tr><td className="body-item">nosotros/-as</td>
					<td className="body-item">decimos</td>
					<td className="body-item">pedimos</td>
				</tr><tr><td className="body-item">vosotros/-as</td>
					<td className="body-item">decís</td>
					<td className="body-item">pedís</td>
				</tr><tr><td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">d<span className="red">i</span>cen</td>
					<td className="body-item">p<span className="red">i</span>den</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>HACER, PONER, SALIR</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">hacer  <p><i>(to do)</i></p></td>
					<td className="head-item">poner  <p><i>(to put)</i></p></td>
					<td className="head-item">salir  <p><i>(to go out)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">hago</td>
					<td className="body-item">pongo</td>
					<td className="body-item">salgo</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">haces</td>
					<td className="body-item">pones</td>
					<td className="body-item">sales</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">hace</td>
					<td className="body-item">pone</td>
					<td className="body-item">sale</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">hacemos</td>
					<td className="body-item">ponemos</td>
					<td className="body-item">salimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">hacéis</td>
					<td className="body-item">ponéis</td>
					<td className="body-item">salís</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">hacen</td>
					<td className="body-item">ponen</td>
					<td className="body-item">salen</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>TENER, VENIR, SENTIR (-e- → -ie-)</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">tener  <p><i>(to have)</i></p></td>
					<td className="head-item">venir  <p><i>(to come)</i></p></td>
					<td className="head-item">sentir  <p><i>(to feel)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">tengo</td>
					<td className="body-item">vengo</td>
					<td className="body-item">s<span className="red">ie</span>nto</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">t<span className="red">ie</span>nes</td>
					<td className="body-item">v<span className="red">ie</span>nes</td>
					<td className="body-item">s<span className="red">ie</span>ntes</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">t<span className="red">ie</span>ne</td>
					<td className="body-item">v<span className="red">ie</span>ne</td>
					<td className="body-item">s<span className="red">ie</span>nte</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">tenemos</td>
					<td className="body-item">venimos</td>
					<td className="body-item">sentimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">tenéis</td>
					<td className="body-item">venís</td>
					<td className="body-item">sentís</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">t<span className="red">ie</span>nen</td>
					<td className="body-item">v<span className="red">ie</span>nen</td>
					<td className="body-item">s<span className="red">ie</span>nten</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>PODER, DORMIR (&nbsp;-o- → -ue-)</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">poder  <p>(to be able)</p></td>
					<td className="head-item">dormir  <p>(to sleep)</p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">p<span className="red">ue</span>do</td>
					<td className="body-item">d<span className="red">ue</span>rmo</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">p<span className="red">ue</span>des</td>
					<td className="body-item">d<span className="red">ue</span>rmes</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">p<span className="red">ue</span>de</td>
					<td className="body-item">d<span className="red">ue</span>rme</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">podemos</td>
					<td className="body-item">dormimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">podéis</td>
					<td className="body-item">dormís</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">p<span className="red">ue</span>den</td>
					<td className="body-item">d<span className="red">ue</span>rmen</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>Words - indicators</h5>
		<p>In sentences often are used adverbs and phrases, which are indicators of present tense. Remember to clearly define Presente de Indicativo.</p>
		<blockquote>
			<p><strong>siempre</strong> - always</p>
			<p><strong>a veces </strong>- sometimes</p>
			<p><strong>a menudo</strong> - often</p>
			<p><strong>normalmente</strong> - usually</p>
			<p><strong>por la mañana, por la tarde, por la noche </strong> - in the morning, in the afternoon, at night</p>
			<p><strong>los lunes, los martes, los domingos</strong> - on monday, on tuesday, on sunday</p>
			<p><strong>cada día, cada semana, cada mes</strong> - each day, each week, each month</p>
		</blockquote>
	</div>;
}