export function PreteritoPerfectoNameEn() {
	return "Pretérito Perfecto Compuesto";
}

export function PreteritoPerfectoEn() {
	return <div>
		<h4>Pretérito Perfecto Compuesto</h4>
		<p>Pretérito Perfecto Compuesto is similar to the Present Perfect Tense in English grammar. Usually this tense is used when speaking about actions that have happened in the recent past.It could describe:</p>
		<blockquote>
			<p>- actions or events in the past that have been recently completed;</p>
			<p>- actions or events in the past that began in the past and are still continuing;</p>
			<p>- completed actions that influence the present or future;</p>
			<p>- experiences in person life that have (or have not) happened.</p>
		</blockquote>
		<p>Pretérito Perfecto Compuesto is complex tense (Compuesto), formed from two verbs: the verb <strong>haber</strong> in the simple present + <strong>participle</strong> in past (Verbo Participio). </p>
		<blockquote><span className="red"><strong>Haber (Present) + Participle (Past)</strong></span></blockquote>
		<p>Only the verb <strong>haber</strong> is conjugated; the past participle remains unchanged regardless of the person.</p>
		<p>Conjugation of verb <strong>haber</strong> in present:</p>
	
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>

				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">he</span></td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">has</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">ha</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">hemos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">habéis</span></td>
					</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">han</span></td>
				</tr>
			</tbody>
		</table>
		<br/>
		<p>The participle in past (Participio Pasado) is made according to this table:</p>
		
			<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">Conjugation</td>
					<td className="head-item">Ending</td>
					<td className="head-item">Changes to ...</td>
					<td className="head-item">Example</td>
					<td className="head-item"> </td>
				</tr>
			</thead>
			<tbody>
				<tr>					
					<td className="body-item"><strong><br/><i>1st conjugation</i></strong></td>
					<td className="body-item"><strong><br/>-ar</strong></td>
					<td className="body-item"><span className="red"><br/>-ado</span></td>
					<td className="body-item">hablar<br />terminar<br />caminar</td>
					<td className="body-item">habl<span className="red">ado</span><br />termin<span className="red">ado</span><br />camin<span className="red">ado</span></td>
				</tr>
				<tr>					
					<td className="body-item"><strong><br /><i>2st conjugation</i></strong></td>
					<td className="body-item"><strong><br />-er</strong></td>
					<td className="body-item"><span className="red"><br />-ido</span></td>
					<td className="body-item">beber<br />comer<br />aprender</td>
					<td className="body-item">beb<span className="red">ido</span><br />com<span className="red">ido</span><br />aprend<span className="red">ido</span></td>
				</tr>
				<tr>					
					<td className="body-item"><strong><br/><i>3rd conjugation</i></strong></td>
					<td className="body-item"><strong><br />-ir</strong></td>
					<td className="body-item"><br /><span className="red">-ido</span></td>
					<td className="body-item">salir <br />decidir<br />venir</td>
					<td className="body-item">sal<span className="red">ido</span><br /> decid<span className="red">ido</span><br /> ven<span className="red">ido</span></td>
				</tr>
				</tbody>
		</table>
		<br />
		Finally the exspression (Haber (Present) + Participle (Past))  is formed as follows:
				<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						hablar <span className="red">(1)</span></td>
					<td className="head-item">
						comer <span className="red">(2)</span></td>
					<td className="head-item">
						sentir <span className="red">(3)</span></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">he</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">he</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">he</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">has</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">has</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">has</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">ha</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">ha</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">ha</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">hemos</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">hemos</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">hemos</span> sent<span className="red">ido</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">habéis</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">habéis</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">habéis</span> sent<span className="red">ído</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">han</span> habl<span className="red">ado</span></td>
					<td className="body-item"><span className="red">han</span> com<span className="red">ido</span></td>
					<td className="body-item"><span className="red">han</span> sent<span className="red">ido</span></td>
				</tr>
			</tbody>
		</table>
		<br/>
		<p>In Pretérito Perfecto Compuesto verbs <strong>estar</strong> and <strong>ser</strong> are regular and conjugate as <strong>estado</strong> and <strong>sido</strong>.</p>
		<p>The participle in this case doesn’t change either by gender or by number and always ends in <strong><span className="red">o</span></strong>. For example:</p>
		
		<blockquote>
			<p>Carmen ha comid<span className="red">o</span> esta mañana–<strong> true</strong></p>
			<p>Carmen ha comid<span className="red">a</span> esta mañana– <span className="red">false</span></p>
			<p>Jorge y Elena han comid<span className="red">o</span> esta mañana – <strong> true</strong></p>
			<p>Jorge y Elena han comid<span className="red">os</span> esta mañana – <span className="red">false</span></p>
		</blockquote>
		<p>Some participles in Spanish are irregular and not conjugated according to the basic rules. You just need to remember it.</p>
		
		<blockquote>
			<p><strong>abrir</strong> (to open) - <strong>abierto</strong> </p>
			<p><strong>ir</strong> (to go) - <strong>ido</strong> </p>
			<p><strong>hacer</strong> (to do) - <strong>hecho</strong> </p>
			<p><strong>freir</strong> (to fry) - <strong>frito</strong> </p>
			<p><strong>ver</strong> (to see) - <strong>visto</strong> </p>
			<p><strong>decir</strong> (to say) - <strong>dicho</strong> </p>
			<p><strong>escribir</strong> (to write) - <strong>escrito</strong> </p>
			<p><strong>volver</strong> (to return) - <strong>vuelto</strong> </p>
			<p><strong>romper</strong> (to break down) - <strong>roto</strong> </p>
			<p><strong>descubrir</strong> (to discover) - <strong>descubierto</strong> </p>
			<p><strong>poner</strong> (to put) - <strong>puesto</strong> </p>
			<p><strong>resolver</strong> (to solve) - <strong>resuelto</strong> </p>
			<p><strong>morir</strong> (to die) - <strong>muerto</strong> </p>	
		</blockquote>
		<p><strong>Examples of Pretérito Perfecto:</strong></p>
		<blockquote>
			<p><i>Lola ha vivido en Madrid toda su vida.</i></p>
			<p><i>Este año Luis ha venido a Londres.</i></p>
			<p><i>He dormido todo el día. </i></p>
			<p><i>Ya he visto esta película dos veces.</i></p>
		</blockquote>
		
		<p>Reflexive verbs, for example, <strong>llamarse, terminarse, olividarse</strong>, as always, are conjugated with a reflexive pronoun. In the case of Preterito Perfecto it will looks the following:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">
						olvidarse <i>(to forget)</i></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">me</span> he olvidado</td>
					</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">te</span> has olvidado</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">se</span> ha olvidado</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">nos</span> hemos olvidado</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">os</span> habéis olvidado</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">se</span> han olvidado</td>
				</tr>
			</tbody>
		</table>
		<br />
		<h5>Words - indicators</h5>
		<p>In sentences often are used adverbs and phrases, which are indicators of Present Perfect Tense. Remember it to define Preterito Perfecto Compuesto.</p>
		<blockquote>
			<p><strong>hoy</strong> - today</p>
			<p><strong>ya</strong> - already</p>
			<p><strong>todavia</strong> - still</p>
			<p><strong>nunca</strong> - never</p>
			<p><strong>últimamente</strong> - lately</p>
			<p><strong>esta mañana (esta tarde, esta noche)</strong> - this morning (this afternoon, tonight)</p>
			<p><strong>esta semana, este mes, este año</strong> - this week, this month, this year</p>
			<p><strong>este siglo</strong> - this century</p>
			<p><strong>desde el año pasado / mes / semana</strong> - since last year / month /week</p>
		</blockquote>	
	</div>;
}