export function PreteritoIndefenidoNameRu() {
	return "Pretérito Indefenido";
}

export function PreteritoIndefenidoRu() {
    return <div>
         <h4>Pretérito Indefenido (Pretérito Simple)</h4>
        <p>Pretérito Indefenido (Pretérito Simple) аналогичен Past Simple в английской грамматике - прошедшее завершенное время и используется для указания на завершение действие в прошлом. Это время может описывать:</p>
        <blockquote>
            <p>- однократное завершённое действие в прошлом;</p>
            <p>- длительные события, происходившие в прошлом, но не имеющие отношения к настоящему времени.;</p>
            <p>- даты и исторические события, биография;</p>
            <p>- краткие последовательные действия, совершенные в прошлом.</p>
        </blockquote>
        <h5>Примеры</h5>
        <blockquote>
            <p>El año pasado viajamos a España.</p>
			<p>Ayer comí una pizza deliciosa.</p>
			<p>Ellos compraron una casa nueva el mes pasado.</p>
			<p>María escribió una carta esta mañana.</p>
		</blockquote>
		<p>Чтобы спрягать в Pretérito Indefenido (Pretérito Simple), просто уберите окончания неопределенной формы глагола и поставьте окончания в соответствии с этой таблицей Это правило относится только к правильным глаголам.</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item"><span className="red"><i>(1)</i></span></td>
					<td className="head-item"><span className="red"><i>(2)</i></span></td>
					<td className="head-item"><span className="red"><i>(3)</i></span></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item"><span className="red">-é</span></td>
					<td className="body-item"><span className="red">-í</span></td>
					<td className="body-item"><span className="red">-í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item"><span className="red">-aste</span></td>
					<td className="body-item"><span className="red">-iste</span></td>
					<td className="body-item"><span className="red">-iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item"><span className="red">-ó</span></td>
					<td className="body-item"><span className="red">-ió</span></td>
					<td className="body-item"><span className="red">-ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item"><span className="red">-amos</span></td>
					<td className="body-item"><span className="red">-imos</span></td>
					<td className="body-item"><span className="red">-imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item"><span className="red">-asteis</span></td>
					<td className="body-item"><span className="red">-isteis</span></td>
					<td className="body-item"><span className="red">-isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item"><span className="red">-aron</span></td>
					<td className="body-item"><span className="red">-ieron</span></td>
					<td className="body-item"><span className="red">-ieron</span></td>
				</tr>
			</tbody>
		</table>
		<h5>Примеры</h5>
        		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">cantar<span className="red"><i>(1)</i></span>петь</td>
					<td className="head-item">beber<span className="red"><i>(2)</i></span>пить</td>
					<td className="head-item">vivir<span className="red"><i>(3)</i></span>жить</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">cant<span className="red">é</span></td>
					<td className="body-item">beb<span className="red">-í</span></td>
					<td className="body-item">viv<span className="red">-í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">cant<span className="red">aste</span></td>
					<td className="body-item">beb<span className="red">iste</span></td>
					<td className="body-item">viv<span className="red">iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">cant<span className="red">ó</span></td>
					<td className="body-item">beb<span className="red">ió</span></td>
					<td className="body-item">viv<span className="red">ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">cant<span className="red">amos</span></td>
					<td className="body-item">beb<span className="red">imos</span></td>
					<td className="body-item">viv<span className="red">imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">cant<span className="red">asteis</span></td>
					<td className="body-item">beb<span className="red">isteis</span></td>
					<td className="body-item">viv<span className="red">isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">cant<span className="red">aron</span></td>
					<td className="body-item">beb<span className="red">ieron</span></td>
					<td className="body-item">viv<span className="red">ieron</span></td>
				</tr>
			</tbody>
		</table>
        
		<p>Возвратные глаголы, например, lavarse, decidirse, ponerse, как обычно, спрягаются с возвратным местоимением. В случае с Pretérito Indefenido (Pretérito Simple), это будет выглядеть следующим образом:</p>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">lavarse<p><i>(умываться)</i></p></td>
					<td className="head-item">decidirse<p><i>(решаться)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">me lav<span className="red">é</span></td>
					<td className="body-item">me decid<span className="red">í</span></td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">te lav<span className="red">aste</span></td>
					<td className="body-item">te decid<span className="red">iste</span></td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">se lav<span className="red">ó</span></td>
					<td className="body-item">se decid<span className="red">ió</span></td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">nos lav<span className="red">amos</span></td>
					<td className="body-item">nos decid<span className="red">imos</span></td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">os lav<span className="red">asteis</span></td>
					<td className="body-item">os decid<span className="red">isteis</span></td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">se lav<span className="red">aron</span></td>
					<td className="body-item">se decid<span className="red">ieron</span></td>
				</tr>
			</tbody>
		</table>
		<p>В Pretérito Indefenido достаточно много глаголов, которые являются неправильными и спрягаются по другим правилам. Их можно разделить на несколько групп:</p>
		<h5> Глаголы с изменением в корне -e- на -i-:</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">pedir <p><i>(мочь)</i></p></td>
					<td className="head-item">sentir<p><i>(чувствовать)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">pedí</td>
					<td className="body-item">sentí</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">pediste</td>
					<td className="body-item">sentiste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">pidió</td>
					<td className="body-item">sintió</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">pedimos</td>
					<td className="body-item">sentimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">pedisteis</td>
					<td className="body-item">sentisteis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">pidieron</td>
					<td className="body-item">sintieron</td>
				</tr>
			</tbody>
		</table>
		<h5> Глаголы с изменением в корне -o- на -u-:</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">dormir<p><i>(cпать)</i></p></td>
					<td className="head-item">morir<p><i>(умирать)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">dormí</td>
					<td className="body-item">morí</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">dormiste</td>
					<td className="body-item">moriste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">durmió</td>
					<td className="body-item">murió</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">dormimos</td>
					<td className="body-item">morimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">dormisteis</td>
					<td className="body-item">moristeis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">durmieron</td>
					<td className="body-item">murieron</td>
				</tr>
			</tbody>
		</table>
	<h5> Глаголы с изменением -i- на -y- (в третьем лице):</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">leer <p><i>(читать)</i></p></td>
					<td className="head-item">construir <p><i>(строить)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr><td className="body-item">yo</td>
					<td className="body-item">leí</td>
					<td className="body-item">construí</td>
				</tr><tr><td className="body-item">tú</td>
					<td className="body-item">leíste</td>
					<td className="body-item">construiste</td>
				</tr><tr><td className="body-item">él/ella/Vd.</td>
					<td className="body-item">le<span className="red">y</span>ó</td>
					<td className="body-item">constru<span className="red">y</span>ó</td>
				</tr><tr><td className="body-item">nosotros/-as</td>
					<td className="body-item">leímos</td>
					<td className="body-item">construimos</td>
				</tr><tr><td className="body-item">vosotros/-as</td>
					<td className="body-item">leísteis</td>
					<td className="body-item">construisteis</td>
				</tr><tr><td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">leyeron</td>
					<td className="body-item">construyeron</td>
				</tr>
			</tbody>
		</table>
		<h5>Глаголы с изменением в окончании (добавляется -j- и исчезает -i- в 3-м лице множественного числа)</h5>
		<table className='table table-custom'>
			<thead>
				<tr className='table-heads'>
					<td className="head-item">&nbsp;</td>
					<td className="head-item">decir <p><i>(сказать)</i></p></td>
					<td className="head-item">traer <p><i>(принести)</i></p></td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="body-item">yo</td>
					<td className="body-item">dije</td>
					<td className="body-item">traje</td>
				</tr>
				<tr>
					<td className="body-item">tú</td>
					<td className="body-item">dijiste</td>
					<td className="body-item">trajiste</td>
				</tr>
				<tr>
					<td className="body-item">él/ella/Vd.</td>
					<td className="body-item">dijo</td>
					<td className="body-item">trajo</td>
				</tr>
				<tr>
					<td className="body-item">nosotros/-as</td>
					<td className="body-item">dijimos</td>
					<td className="body-item">trajimos</td>
				</tr>
				<tr>
					<td className="body-item">vosotros/-as</td>
					<td className="body-item">dijisteis</td>
					<td className="body-item">trajisteis</td>
				</tr>
				<tr>
					<td className="body-item">ellos/-as/Vds.</td>
					<td className="body-item">dijeron</td>
					<td className="body-item">trajeron</td>
				</tr>
			</tbody>
		</table>

		<h5> Слова - индикаторы</h5>
		<p>В предложениях часто используются наречия и фразы, которые указывают на прошедшее завершенное время. Запомните их, чтобы четко определять Pretérito Indefenido.</p>
		<blockquote>
			<p><strong>ayer</strong> - вчера</p>
			<p><strong>anteayer</strong>- позавчера</p>
			<p><strong>anoche </strong> - вчера вечером</p>
			<p><strong>la semana pasada</strong> - на прошлой неделе</p>
			<p><strong>el mes pasado, el invierno pasado, el año pasado </strong> - в прошлом месяце, в прошлую зиму,в прошлом году </p>
			<p><strong>hace una semana </strong> - неделю назад</p>
			<p><strong>hace 2 meses, hace 5 años</strong> - два месяца назад, пять лет назад</p>
            <p><strong>hace mucho...</strong> - давно</p>
        
		</blockquote>
	</div>;
}