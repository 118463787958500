import React from 'react';
import PillsTab from '../pillsTab/pillsTab';

import { PresentedeIndicativoEn, PresentedeIndicativoNameEn } from './en/presentedeIndicativo'
import { PreteritoPerfectoEn, PreteritoPerfectoNameEn } from './en/preteritoperfecto'
import { PreteritoImperfectoEn, PreteritoImperfectoNameEn } from './en/preteritoimperfecto'
import { PreteritoIndefenidoEn, PreteritoIndefenidoNameEn } from './en/preteritoindefenido'

import { PresentedeIndicativoRu, PresentedeIndicativoNameRu } from './ru/presentedeIndicativo'
import { PreteritoPerfectoRu, PreteritoPerfectoNameRu } from './ru/preteritoperfecto'
import { PreteritoImperfectoRu, PreteritoImperfectoNameRu } from './ru/preteritoimperfecto'
import { PreteritoIndefenidoRu, PreteritoIndefenidoNameRu } from './ru/preteritoindefenido'

import { useTranslation } from 'react-i18next';
import './tenseInfo.css'

export default function TenseInfo() {
    const { i18n } = useTranslation();
    const tabFunc = (comp) => React.createElement(comp);
    const bodyFunc = (comp) => React.createElement(comp);
    const getComponent = (en, ru) => i18n.resolvedLanguage === 'ru' ? ru : en;

    const PresentedeIndicativoName = getComponent(PresentedeIndicativoNameEn, PresentedeIndicativoNameRu);
    const PreteritoPerfectoName = getComponent(PreteritoPerfectoNameEn, PreteritoPerfectoNameRu);
    const PreteritoImperfectoName = getComponent(PreteritoImperfectoNameEn, PreteritoImperfectoNameRu);
    const PreteritoIndefenidoName = getComponent(PreteritoIndefenidoNameEn, PreteritoIndefenidoNameRu);

    const PresentedeIndicativo = getComponent(PresentedeIndicativoEn, PresentedeIndicativoRu);
    const PreteritoPerfecto = getComponent(PreteritoPerfectoEn, PreteritoPerfectoRu);
    const PreteritoImperfecto = getComponent(PreteritoImperfectoEn, PreteritoImperfectoRu);
    const PreteritoIndefenido = getComponent(PreteritoIndefenidoEn, PreteritoIndefenidoRu);

    return <section className="paddingTop32 paddingBottom48">
        <PillsTab
            tabs={[PresentedeIndicativoName, PreteritoPerfectoName, PreteritoImperfectoName, PreteritoIndefenidoName]}
            body={[PresentedeIndicativo, PreteritoPerfecto, PreteritoImperfecto, PreteritoIndefenido]}
            tabFunc={tabFunc}
            bodyFunc={bodyFunc}
        />
    </section>
}