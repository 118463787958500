import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();
    return (
        <section data-bs-version="5.1" className="footer3 cid-tuNTtD5KKa" id="footer3-o">
        <div className="container">
            <div className="media-container-row align-center mbr-white">
                <div className="row row-links">
                    <ul className="foot-menu">
                        <li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/entrenador">{t('menu.Trainer')}</a>
                        </li>
                        <li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/conjugador">{t('menu.Conjugator')}</a>
                        </li>
                        <li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/grammar">{t('menu.Grammar')}</a>
                        </li>
                        <li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/tests">{t('menu.Tests')}</a>
                        </li>
                        {/*<li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/sinonimos">Sinónimos</a>
                        </li>*/}
                        <li className="foot-menu-item mbr-fonts-style display-7">
                            <a className="text-black" href="/privacy">{t('menu.Privacy')}</a>
                        </li>
                    </ul>
                </div>
                <div className="row social-row">
                    <div className="social-list align-right pb-2">
                        <div className="soc-item">
                            <a href="https://www.facebook.com/profile.php?id=61550767157118" target="_blank" rel="noreferrer">
                                <span className="mbr-iconfont mbr-iconfont-social socicon-facebook socicon"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row row-copirayt">
                    <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                        © Copyright 2023 Verbo Go. All Rights Reserved.
                    </p>
                </div>
            </div>
            </div>
        </section>
    );
}
