import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactForm() {
    const { t } = useTranslation();

    const ShowMessage = (className) => {
        document.getElementsByClassName(className)[0].removeAttribute("hidden");
        setTimeout(function () { document.getElementsByClassName(className)[0].setAttribute("hidden", "hidden") }, 5000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = Array.from(e.target.elements)
            .reduce((obj, input) => Object.assign(obj, { [input.name]: input.value }), {});

        fetch(`api/contact`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        .then((response) => {
            if (response.status !== 200) {
                ShowMessage("alert-danger");
                throw new Error(response.statusText);
            }

            ShowMessage("alert-success");
            e.target.reset();
        });
    };

    return (
        <section className="paddingTop32 paddingBottom48 cid-tv04EfegOV">
            <div className="mbr-section-head">
                <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-7">
                    <strong>{t('contact.Title')}</strong>
                </h3>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col-lg-8 mx-auto mbr-form" data-form-type="formoid">
                    <form onSubmit={handleSubmit} className="mbr-form form-with-styler" data-form-title="Form Name">
                        <div className="row">
                            <div hidden="hidden" data-form-alert="" className="alert alert-success col-12">{t('contact.Success')}</div>
                            <div hidden="hidden" data-form-alert-danger="" className="alert alert-danger col-12">{t('contact.Error')}</div>
                        </div>
                        <div className="dragArea row">
                            <div className="col-md col-sm-12 form-group mb-3" data-for="name">
                                <input type="text" name="name" placeholder={t('contact.Name')} data-form-field="name" className="form-control" id="name-form5-y" required="required" />
                            </div>
                            <div className="col-md col-sm-12 form-group mb-3" data-for="email">
                                <input type="email" name="email" placeholder={t('contact.Email')} data-form-field="email" className="form-control" id="email-form5-y" required="required" />
                            </div>

                            <div className="col-12 form-group mb-3" data-for="textarea">
                                <textarea name="text" placeholder={t('contact.Message')} data-form-field="textarea" className="form-control" id="textarea-form5-y" required="required"></textarea>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 align-center mbr-section-btn">
                                <button type="submit" className="btn btn-primary display-4">{t('contact.SendMessage')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}